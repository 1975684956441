import { useEffect } from 'react';

const useFetchDataSources = (user, navigate, setDatasource,itemID=null) => {
  useEffect(() => {
    const fetchDataSources = async () => {
      try {
        if (user && user.uid) {
          console.log("check whether user logged in:", user.uid);
          const response = await fetch('https://api.qquest.io/data-source-list-portal', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ UserId: user.uid,itemID:itemID }),
          });
          const data = await response.json();
          setDatasource(data.data);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.log("Error fetching data sources:", error);
      }
    };

    fetchDataSources();
  }, [user, navigate, setDatasource,itemID]); // Only rerun if user, navigate, or setDatasource changes
};

export default useFetchDataSources;
