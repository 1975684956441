import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase from '../firebase/firebaseConfig';
import { useNavigate,useLocation } from 'react-router-dom';

// Create the Auth context
const AuthContext = createContext(null);
const EmailList = ['chelseychan914@gmail.com','vinicius@mileiq.com','colleen@mileiq.com' ]

// Hook for child components to get the auth object and re-render when it changes
export const useAuth = () => useContext(AuthContext);

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth()
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null); // State to store the token
    const [loading, setLoading] = useState(true);  // New loading state
    const [initialLoad, setInitialLoad] = useState(null);  // Track the initial load
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        // Setup Firebase auth persistence
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .catch(error => console.error("Firebase persistence error:", error));

        // Subscribe to user on mount
        const unsubscribe = firebase.auth().onAuthStateChanged(currentUser => {
            if (currentUser) {
                currentUser.getIdToken().then(idToken => {
                    setToken(idToken); // Store the ID token in the state
                    const userRef = firebase.database().ref(`users/${currentUser.uid}`);
                    userRef.once('value', snapshot => {
                        const userData = snapshot.val();
                        let planType = userData?.subscription?.planId ?? "";
                        const first_name = userData?.first_name?? "";
                        const last_name = userData?.last_name?? "";
                        const department = userData?.department?? "";
                        const company = userData?.company?? "";
                        const industry = userData?.industry?? "";
                        const role = userData?.role?? "";
                        if (EmailList.includes(currentUser.email)) {planType = 'free trial'}
                        setUser({
                            uid: currentUser.uid,
                            email: currentUser.email,
                            planType,
                            token:idToken,
                            first_name:first_name,
                            last_name:last_name,
                            department:department,
                            company: company,
                            role:role,
                            industry:industry
                        });
                        setLoading(false);  // Set loading to false once we get the user state
                        if (initialLoad) {
                            navigate(planType ? "/dashboard/Assistants" : "/pricing");
                            setInitialLoad(false)
                            console.log("current initialLoad value:",initialLoad)
                        }
                    })
                });
            } else {
                setUser(null);
                if ( location.pathname !== '/register' && location.pathname !== '/forget-password') {
                    navigate("/login");
                }
            }
        });
        
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate,initialLoad]);

    const signIn = async (email, password) => {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            setInitialLoad(true)
        } catch (error) {
            throw new Error(error.message || "Failed to sign in");
        }
    };

    const logout = async () => {
        try {
            await firebase.auth().signOut();
            navigate('/login');
        } catch (error) {
            console.log('Logout failed:', error);
        }
    };

    // The value prop is where we define what values that are accessible to child components
    const value = { user, signIn , logout, loading};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
