import React, { useEffect, useState } from "react";
import button_plus from "../assets/button_white.svg";
import button_icon from "../assets/IconButton.svg";
import ActionMenu from "./ActionMenu";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import useFetchDataSources from '../hooks/useFetchDataSources';
import Modal from "./AddDataSourceModal";
import {format} from 'date-fns';



const DataSource = () => {
  const [datasource, setDatasource] = useState([]);
  const { user,loading } = useAuth();
  //console.log(user);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    // Redirect if not logged in and not loading
    if (!user && !loading) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  useFetchDataSources(user, navigate, setDatasource);

 

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Loading or not logged in...</div>;  // This line might never be reached if the user is always redirected when not logged in
  }


    return (
        <>
        <div className="flex justify-between items-center w-full">
          <h1>Manage Data Sources</h1>
          <button className="flex items-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50" onClick={openModal}>
            <img src={button_plus} alt='Add Data Source' className='w-5 h-5 mr-2'/>
            <p className="text-white">Add Data Source</p>
        </button>
        <Modal isOpen={isModalOpen} onClose={closeModal} user={user}>
                <p>This is the content of the modal</p>
        </Modal>
        </div>
        <div className="overflow-x-auto mt-1 mb-1 rounded-md shadow">
            <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ID
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Data Source Name
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Source Type
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Created At
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            
                        </th>
                        {/* Add other headers as needed */}
                    </tr>
                </thead>
              
                <tbody className="bg-white divide-y divide-gray-200">
                  
                  {Array.isArray(datasource) && datasource.map((item, index) => (
                        <tr key={index}>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{index+1}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{item.name}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{item.DatabaseType}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{format(new Date(item.created_at), 'PPPpp')}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {item.CountAssit > 0 ? 
                                  <p className="text-[#166534] bg-[#DCFCE7] rounded-lg px-4 py-1 w-30 text-center ">Active</p> 
                                  : <p className="text-[#1F2937] bg-[#E5E7EB] rounded-lg px-4 py-1 w-30 text-center ">Not in Use</p>}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                              <ActionMenu 
                                buttonIcon={button_icon} 
                                itemId={item.CountAssit > 0 ? "Active" : item.source_id}
                                page ="DataSource"
                                user = {user}/>
                            </td>
                           
                            {/* Render other fields as needed */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
      </>
    )
}

export default DataSource