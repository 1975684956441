import React, { useEffect, useState } from "react";
import basic from "../assets/business 1.svg";
import pro from "../assets/pro 1.svg";
import business from "../assets/basic 1.svg";
import check_icon from "../assets/check.svg";
import firebase from "../firebase/firebaseConfig";
import { useNavigate } from 'react-router-dom';
import qquest_icon from "../assets/logo.png";



const data = [
  {
    id: "price_1PdPBmRor9lqqUkVjnG9Rsex",
    src: basic,
    title: "Starter",
    price: "99",
    cta: "Starter",
    features : [
      { text: "1 AI Assistant" },
      { text: "1 Data Sources Connection" },
      { text: "Assistant Management" },
      { text: "Assistant Customization" },
      { text: "Assistant Share" },
      { text: "Unlimited AI" }
    ]
  },
  {
    id: "price_1PNKTgRor9lqqUkVfrGuM4E2",
    src: business,
    title: "Growth",
    price: "499",
    cta: "Growth",
    features : [
      { text: "Unlimited AI Assistants" },
      { text: "Unlimited Data Connection" },
      { text: "Assistant Management" },
      { text: "Assistant Customization" },
      { text: "Assistant Share" },
      { text: "Unlimited AI" }
    ]
  },
  {
    id: "enterprise",
    src: pro,
    title: "Pro",
    price: "",
    cta: "Contact Us",
    features:[
      {text: "All included in starter"},
      { text: "Customized Solutions" },
      { text: "Meeting Support" }
    ]
  },

];
const Pricing = () => {
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [planType, setPlanType] = useState("");

  const navigate = useNavigate();


  useEffect(()=>{
    firebase.auth().onAuthStateChanged((user) => {
        if(user){
            setUserId(user.uid);
            setUserName(user.email.split('@')[0]);
            const userRef = firebase.database().ref("users/" + user.uid);
            userRef.on("value",(snapshot) => {
              const userVal = snapshot.val();
              if(user) {
                setPlanType(userVal && userVal.subscription && userVal.subscription.planId ? userVal.subscription.planId : "");
                console.log(planType)
              }
            })
        }else{
            setUserId("");
            setUserName("");
        }
    })
  },[userId]);

  

  const checkout = (plan) => {
    console.log("check whether user logged in:",userId)
    if (userId) {
      fetch('https://api.qquest.io/create-subscription-checkout-session', {
        method: "POST",
     
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ plan: plan, customerId: userId }),
      })
      .then((res) => {
        if (res.ok) return res.json();
        console.log(res);
        return res.json().then((json) => Promise.reject(json));
      })
        .then(({ session }) => {
          window.location = session.url;
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      navigate('/login');
    }
    
  }

  const handleLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        // Navigation to login page after successful logout
        navigate('/login');
      })
      .catch((error) => {
        // Handle errors here, such as displaying an error message
        console.error('Logout failed:', error);
      });
  };



  return (
    <>
      <div className="flex flex-col items-center w-full mx-auto min-h-screen bg-[#FAFAFA] overflow-x-hidden">
        <div className="flex justify-between items-center w-full px-12 py-6 h-20 bg-[#F5F5F5]">
          <div className="flex items-center py-2">
                <img src={qquest_icon} alt='Qquest' className='w-12 mr-4'/>
                  <div className="  text-xl font-bold capitalize logo text-black">Qquest</div>
          </div>
          <div className="flex justify-center items-center gap-2">
            {!userId ? (
              <a
                href="/login"
                className="bg-white px-4 py-2 uppercase w-auto rounded-lg text-xl text-[#4f7cff]  font-semibold"
              >
                Login
              </a>
            ) : (
              <div className="flex justify-center items-center space-x-4">
                <span className="text-gray-800 text-lg">{userName}</span>
                <button
                  onClick={handleLogout}
                  className="flex items-center justify-center bg-white border border-gray-200 px-4 py-1 mt-1 mb-1 rounded-md shadow text-[#1E293B]"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className="z-50 place-items-center  w-9/12 mx-auto
        mt-4"
        >

 <div
          className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 z-50 place-items-center  w-9/12 mx-auto
        mt-10"
        > 
          
          
          {data.map((item, idx) => (
            
            <div
              key={idx}
              className={`bg-white px-6 py-8 rounded-xl text-[#F58F29] w-full mx-auto flex flex-col h-full
              place-items-center ${
                planType === item.id &&
                "border-[16px] border-green-400"
              }`}
            >
              <img
                src={item.src}
                alt=""
                width={200}
                height={200}
                
              />
              <div className="text-4xl text-slate-700 text-center py-4 font-bold">
                {item.title}
              </div>
              < ul className="flex-1 flex flex-col w-full">
              <li className="text-lg  px-2 py-6 text-slate-500"> What is included:
              </li>

              {item.features.map((item_f, index_f) => (
                <li key={index_f} className="flex p-1">
                  <img src={check_icon} alt="Qquest" className="w-6 mr-4" />
                  <p className="lg:text-sm text-xs text-center px-6 text-slate-500">{item_f.text}</p>
                </li>
              ))}
         
                
              </ul>
              { item.title !== "Pro" && 
              <div className="text-4xl text-center font-bold py-4">
                ${item.price} <p className="text-sm text-center font-bold py-1">/per month</p>
              </div>
              }
              <div className="mx-auto flex justify-center items-center mt-3">
                { item.id === "enterprise" ? (
                  <a
                  href = "https://www.qquest.io/request-demo"
                  className="bg-[#F58F29] text-white rounded-md text-base uppercase py-2 px-4 font-bold inline-flex"
                  >
                  {item.cta}
                </a>
                ) : (
                  <button
                  onClick={() => checkout(item.id)}
                  className="bg-[#F58F29] text-white rounded-md text-base uppercase w-24 py-2 font-bold"
                >
                  Start
                </button>
                )}
                
                </div>
               
            </div>
          ))}
        </div>
      </div>
      </div>
    </>
  );
};
export default Pricing;