import { useCallback } from 'react';

const useFetchAnswers = () => {
  const fetchAnswers = useCallback(async (user, navigate, database,question, setSQLCode,setMessageId,setMessages,setRawData,assistantId) => {
      try {
        if (user && user.uid) {
          console.log("check whether user logged in in answer fetch:", user.uid);
          ///need to add bearer token later
          const response = await fetch('https://api.qquest.io/data-source-selected-from-chat-view', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ 
              user_id:user.uid,
              Email: user.email ,
              assist_id:assistantId,
              database: database,
              question: question,
              request_source:"Train Assistant"
            }),
          });
          const data = await response.json();
          setSQLCode(JSON.parse(data)["SQL Code"]);
          setMessageId(JSON.parse(data).message_id);
          setMessages(JSON.parse(data).content);
          setRawData(JSON.parse(data)['Raw Data']);

          console.log(data)
          console.log(JSON.parse(data)["SQL Code"])
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.log("Error fetching assistants", error);
      }
    }, []);

  return fetchAnswers;
};

export default useFetchAnswers;
